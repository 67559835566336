<template>
  <v-card style="margin-left: 20px; margin-right: 20px">
    <v-card
      style="
        padding-left: 3%;
        font-size: x-large;
        padding-top: 16px;
        padding-bottom: 2px;
        background-color: #0097a7;
        margin-top: 26px;
        color: #fff;
      "
    >
      <P>Attendance Report Download</P></v-card
    >
    <v-layout row wrap align-center class="mx-5">
      <v-flex xs6>
        <v-autocomplete
          class="ma-2"
          v-model="centerData"
          :items="getCenterListData"
          :item-text="(item) => item.center_code + ' - ' + item.name"
          item-value="center_code"
          menu-props="auto"
          label="Select Center"
          hide-details
          prepend-icon="account_balance"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              close
              @input="removePaper(data.item)"
              :selected="data.selected"
              class="chip--select-multi"
              >{{ data.item.center_code + " - " + data.item.name }}</v-chip
            >
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-tile-content v-text="data.item"></v-list-tile-content>
            </template>
            <template v-else>
              <v-list-tile-content>
                <v-list-tile-title
                  v-html="data.item.center_code + ' - ' + data.item.name"
                ></v-list-tile-title>
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="examdate"
          :items="routineDate"
          :item-text="(item) => item.date"
          item-value="date"
          attach
          chips
          label="Select Exam Date"
          prepend-icon="event"
          multiple
        ></v-select>
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="reportType"
          :items="report"
          attach
          chips
          label="Select Report"
          prepend-icon="event"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm12 md12 class="my-3">
        <v-layout justify-center row fill-height align-center>
          <v-btn outline round large @click="exportAttendancexls()">
            <v-icon left dark>download</v-icon>Download
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-card
      style="
        padding-left: 3%;
        font-size: x-large;
        padding-top: 16px;
        padding-bottom: 2px;
        background-color: #0097a7;
        margin-top: 26px;
        color: #fff;
      "
    >
      <P>Practical/Viva Marks Download</P>
    </v-card>
    <v-layout row class="md12" style="padding: 10px">
      <v-flex xs6>
        <v-autocomplete
          class="ma-2"
          v-model="searchParam.practical_center_code"
          :items="getPracticalWantListByCenter"
          :item-text="(item) => item.center_code + ' - ' + item.center_name"
          item-value="center_code"
          menu-props="auto"
          label="Select P/V Center"
          hide-details
          prepend-icon="account_balance"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              close
              @input="removePaper(data.item)"
              :selected="data.selected"
              class="chip--select-multi"
              >{{ data.item.center_code + " - " + data.item.center_name }}</v-chip
            >
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-tile-content v-text="data.item"></v-list-tile-content>
            </template>
            <template v-else>
              <v-list-tile-content>
                <v-list-tile-title
                  v-html="data.item.center_code + ' - ' + data.item.center_name"
                ></v-list-tile-title>
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          class="ma-2"
          name="Pepar Code"
          label="Pepar Code"
          v-model="searchParam.pepar_code"
          prepend-icon="edit"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row class="md12">
      <v-flex xs12 class="center" style="text-align: center">
        <v-btn dark color="indigo" @click="downloadPracticalMarks()">
          Download Practical Marks
        </v-btn>

        <v-btn dark color="indigo" @click="downloadVivaMarks()">
          Download Viva Marks
        </v-btn>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      reportType: "",
      centerData: "",
      searchParam: {},
      report: ["All", "Present", "Absent", "Reported", "Absent Verification"],
      examdate: [],
      selectedFruits: [],
    };
  },

  created() {
    this.fetchCenterListData();
    this.fetchRoutineDateData();
    this.getPracticalVivaCenter();
  },

  computed: {
    getCenterListData() {
      return this.$store.getters.getCenterData;
    },
    routineDate() {
      return this.$store.getters.getRoutineDate;
    },
    getPracticalWantListByCenter() {
      return this.$store.getters.getCenterListByPractical;
    },
  },

  methods: {
    fetchCenterListData() {
      console.log("Tanvir! called center");
      let peram = {};
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      this.$store.dispatch("getCenterData", peram);
    },

    fetchRoutineDateData() {
      console.log("Tanvir! called date");
      let peram = {};
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.type = 1;
      this.$store.dispatch("getRoutineDate", peram);
    },

    exportAttendancexls() {
      let peram = {};
      peram.exam_code = this.searchParam.exam_code;
      peram.exam_year = this.searchParam.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.centers = this.centerData;
      peram.dates = this.examdate;
      if (this.reportType == "All") {
        peram.report_type = "ALL";
      } else if (this.reportType == "Present") {
        peram.report_type = "P";
      } else if (this.reportType == "Absent") {
        peram.report_type = "A";
      } else if (this.reportType == "Absent Verification") {
        peram.report_type = "AV";
      } else {
        peram.report_type = "R";
      }
      console.log("Tanvir Attendance peram------>", peram);
      if (this.reportType) {
        this.$store.dispatch("downloadAttendanceReportExl", peram);
      }
    },

    removePaper(item) {
      console.log("Item to remove:", item);
      const index = this.searchParam.findIndex(
        (selectedItem) => selectedItem.center_code === item.center_code
      );
      console.log("Index to remove:", index);
      if (index >= 0) {
        this.searchParam.splice(index, 1);
        console.log("searchParam after removal:", this.searchParam);
      }
    },

    getPracticalVivaCenter(){
      let peram = {};
      peram.distinct= 'true';
      peram.exam_code = this.searchParam.exam_code;
      peram.year = this.searchParam.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.type = 2;
      this.$store.dispatch("fetchPracticalWiseCenterData", peram);
    },

    downloadPracticalMarks() {
      let peram = {};
      peram.exam_code = this.searchParam.exam_code;
      peram.exam_year = this.searchParam.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.center_code = this.searchParam.practical_center_code;
      peram.pepar_code = this.searchParam.pepar_code
      peram.type = 2;
      this.$store.dispatch("downloadPracticalReportExl", peram);
    },
    downloadVivaMarks() {
      let peram = {};
      peram.exam_code = this.searchParam.exam_code;
      peram.exam_year = this.searchParam.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.center_code = this.searchParam.practical_center_code;
      peram.pepar_code = this.searchParam.pepar_code
      peram.type = 3;
      this.$store.dispatch("downloadVivaReportExl", peram);
    },
  },
};
</script>

<style></style>
